


























import {Component, Vue} from 'vue-property-decorator';
import {getOrders} from '@/api/shipCardOrder'

@Component({})
export default class Card extends Vue {
  private orderDto = new Array()

  private async activated() {
    await this.getOrderList()
  }

  private async getOrderList() {
    const result = await getOrders()
    this.orderDto = result
  }

  private getCardType(cardType: number) {
    switch (cardType) {
      case 1:
        return `单次卡`;
      case 2:
        return '月卡';
      case 3:
        return '季卡';
      case 4:
        return '半年卡';
      case 5:
        return '年卡';
      case 6:
        return '两年卡';
      case 7:
        return '家庭卡';
      default:
        return '';
    }
  }

  private getOrderState(item: any) {
    if (item.orderState === 0) {
      return '未付款'
    } else if (item.orderState === 1) {
      if (item.cardType === 1) {
        return `剩余：${item.remainingTimes}次`
      } else {
        return `到期：${item.expirationTime}`
      }
    }
  }

  private async handleClickA(item: any) {
    if (item.orderState === 0) {
      await this.$router.push({
        path: '/user/buy-card-order',
        query: {
          orderId: item.id
        }
      });
    }
  }
};
