import request from '@/utils/request'
import {ShipCardOrderDto} from '@/dto/ShipCardOrderDto';

const url = '/keep-fit/shipCardOrder'

// 生成订单
export function makeOrder(orderData: any): any {
  return new Promise((resolve, reject) => {
    request({
      url: `${url}`,
      method: 'post',
      data: orderData
    }).then((res) => resolve(res.data)).catch((err) => reject(err));
  })
}

// 获取当前未付款订单
export function currentOrder(orderId: string | Array<string | null>): any {
  return new Promise<ShipCardOrderDto>((resolve, reject) => {
    request({
      url: `${url}/current`,
      method: 'get',
      params: {
        orderId
      }
    }).then((res) => resolve(res.data)).catch((err) => reject(err));
  })
}

export function getOrders(): any {
  return new Promise<ShipCardOrderDto[]>((resolve, reject) => {
    request({
      url: `${url}/orders`,
      method: 'get'
    }).then((res) => resolve(res.data)).catch((err) => reject(err));
  })
}
